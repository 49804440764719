import React from 'react';
import 'react-activity/dist/react-activity.css';
import '@lib/styles.css';
import { MDXProvider } from '@mdx-js/react';
import { Wrapper } from '@components/molecules/MDXWrapper';
import CodeBlock from '@components/atoms/CodeBlock';
import { lightTheme, darkTheme } from '@lib/theme';
import { ThemeProvider } from 'emotion-theming';
import useDarkMode from 'use-dark-mode';
import '@app/less/antd-custom.less';
import Router from 'next/router';
import * as antd from 'antd';
import withGA from 'next-ga';
import brand from '@lib/brand';
import Button from '@atoms/Button';
import Announcements from '@containers/Announcements';
import { FirebaseProvider } from '@lib/firebase';
import * as Sentry from '@sentry/node';
import dynamic from 'next/dynamic';
const monitor = dynamic(() => import(`@lib/monitor`), { ssr: false });

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}
type ObjProps = {
  [name: string]: any;
};

const mdComponents = {
  code: CodeBlock,
  wrapper: Wrapper,
  Announcements: Announcements,
  ...antd,
  brand,
  Button
};

type MyAppProps = {
  Component: any;
  pageProps: any;
  err: any;
};

const MyApp: React.FC<MyAppProps> = ({ Component, pageProps, err }) => {
  const { value } = useDarkMode(false, {
    storageKey: undefined,
    onChange: undefined
  });
  const theme = value ? darkTheme : lightTheme;
  return (
    <ThemeProvider theme={theme}>
      <MDXProvider components={mdComponents}>
        <FirebaseProvider>
          <Component {...pageProps} err={err} />
        </FirebaseProvider>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default withGA('UA-169366848-1', Router)(MyApp);
